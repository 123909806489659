<template>
  <div
    class="hello"
    style="padding-bottom: 80px; min-height: 100vh; background-color: #f3f3f3"
  >
    <van-sticky>
      <van-nav-bar
        style="background: rgba(49, 151, 253, 1); color: #ffffff"
        @click-left="onClickLeft"
      >
        <template #title>
          <span style="color: #ffffff" @click="changeMatou">{{
            page_title
          }}</span>
        </template>
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
      </van-nav-bar>
      <van-dropdown-menu swipe-threshold="3">
        <van-dropdown-item
          title="日期"
          v-model="month"
          @change="changeMonth"
          :options="monthArr"
        >
          <template #title>
            <van-icon name="calendar-o" style="margin: 0 5px; color: #2c92ff" />
            {{ tmpData.month }}
          </template>
        </van-dropdown-item>
        <van-dropdown-item
          v-model="tmpData.up_id"
          @change="changeUp"
          :options="matouArr"
        >
          <template #title> 登船({{ tmpData.up_title }}) </template>
        </van-dropdown-item>
        <van-dropdown-item
          v-model="tmpData.down_id"
          @change="changeDown"
          :options="matouArr"
        >
          <template #title> 下船({{ tmpData.down_title }}) </template>
        </van-dropdown-item>
        <van-dropdown-item
          v-model="tmpData.typeid"
          @change="changeType"
          :options="typeArr"
        >
          <template #title> 系列({{ tmpData.type_title }}) </template>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-sticky>
    <template v-if="hasLoad">
      <div v-if="dataList.length">
        <template v-for="(day, idx) in dataList" :key="idx">
          <div>
            <van-divider
              :style="{
                color: '#1989fa',
                borderColor: '#1989fa',
                padding: '5px 10px',
                fontSize: '16px',
              }"
              >{{ day.date }} {{ day.week }}</van-divider
            >
            <div
              class="hot_line"
              @click="goUrl('/boat/detail/' + item.lid)"
              v-for="(item, idx) in day.list"
              :key="idx"
            >
              <div class="wbox">
                <div class="start">
                  {{ item.up_time }}
                  <div style="font-size: 12px">
                    <span class="bgs">起</span>{{ item.up_title }}
                  </div>
                </div>
                <div class="end">
                  {{ item.down_time }}
                  <div style="font-size: 12px">
                    <span class="bgs">终</span>{{ item.down_title }}
                  </div>
                </div>
                <div class="jt"><span class="arrow"></span></div>
                <div class="center">
                  <span class="price"
                    >￥<b>{{ item.price }}</b></span
                  >
                </div>
                <div class="trans">
                  {{ item.btitle }}({{ item.day + "天" + item.night + "晚" }})
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <van-empty v-else image="search" description="没有数据" />
    </template>
    <my-footer :view="hasLoad" :showElse="false" :showTabBar="true"></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
<script>
import { ref } from "vue";
import {
  showNotify,
  showToast,
  BarrageProps,
  BarrageItem,
  BarrageInstance,
} from "vant";
import MyBox from "@/components/MyBox.vue";
import MyFooter from "@/components/myFooter.vue";
import ShareBox from "@/components/ShareBox.vue";

export default {
  name: "makeOrder",
  components: {
    MyBox,
    MyFooter,
    ShareBox,
  },
  data() {
    return {
      hasLoad: false,
      loading: false,
      tmpData: false,
      shareData: false,
      loadShare: false,
      page_title: "船期表",
      month: "本月",
      matouArr: [], //码头数组
      dataList: [],
      typeArr: [],
      showdate: false,
      today: {
        date: "",
        week: "",
      },
      monthArr: [],
      num: 0,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return { onClickLeft };
  },
  mounted() {
    // window.addEventListener('scroll',this.scrollToTop)

    this.tmpData = {
      st: "newmonth",
      month: "",
      ns: 0,
      up_id: 0,
      down_id: 0,
      typeid: 0,
      up_title: "全部",
      down_title: "全部",
      type_title: "全部",
    };
    // 初始化数据
    this.page_title = `船期表`;
    this.monthArr = localStorage.getItem("monthArr")
      ? JSON.parse(localStorage.getItem("monthArr"))
      : [];

    let arr = localStorage.getItem("matouArr")
      ? JSON.parse(localStorage.getItem("matouArr"))
      : [];
    this.matouArr.push({
      value: 0,
      text: "全部",
    });
    arr.map((item) => {
      this.matouArr.push({
        value: item.id,
        text: item.title,
      });
    });

    let typearr = localStorage.getItem("typeArr")
      ? JSON.parse(localStorage.getItem("typeArr"))
      : [];
    this.typeArr.push({
      value: 0,
      text: "全部系列",
    });
    typearr.map((item) => {
      this.typeArr.push({
        value: item.id,
        text: item.title,
      });
    });
    let date = new Date();
    let m = date.getMonth() + 1;
    this.tmpData.month = this.month = `${date.getFullYear()}-${
      m < 10 ? "0" + m : m
    }`;

    this.shareData = {
      title: "三峡游轮船期表",
      desc: "",
      link: false, // 默认获取当前页面 要分享的页面地址不允许存在#参数
      imgUrl:
        "https://m.xingchuanly.com/oss/2025-02-24/6ce05e1d84dd2dd2bbffbd6e1495aac3.png?x-oss-process=image/resize,m_mfit,w_120,h_120", // 分享出去链接图片
    };
    this.loadShare = true;

    this.getList();
  },
  methods: {
    scrollToTop() {
      let curScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let vh = window.innerHeight;

      if (curScrollTop < vh) {
        curScrollTop = vh;
      }

      let itemHeight = 85;
      this.num = Math.ceil(curScrollTop / itemHeight) * 4;

      if (this.num < 100) {
        this.num = 100;
      }

      this.dataList.forEach((item, idx) => {
        var abs = 0;
        abs = Math.abs(item.ac_num - this.num);
        console.log(idx, item.ac_num, abs, this.num);
        item.show = abs < 100;
      });
    },
    canShow() {
      let abs = Math.abs(this.dataList[idx].ac_num - this.num);
      return abs < 200;
    },
    changeMonth(val) {
      this.tmpData.month = val;
      this.getList();
    },
    changeUp(val) {
      this.tmpData.up_id = val;
      this.matouArr.map((item) => {
        if (item.value == val) {
          this.tmpData.up_title = item.text;
        }
      });
      this.getList();
    },
    changeDown(val) {
      this.tmpData.down_id = val;
      this.matouArr.map((item) => {
        if (item.value == val) {
          this.tmpData.down_title = item.text;
        }
      });
      this.getList();
    },
    changeType(val) {
      this.tmpData.typeid = val;
      this.typeArr.map((item) => {
        if (item.value == val) {
          this.tmpData.type_title = item.text;
        }
      });
      this.getList();
    },
    getList() {
      this.loading = true;
      let form = {
        st: this.tmpData.st, // month day boat line 查询类型
        month: this.tmpData.month,
        ns: this.tmpData.ns,
        sid: this.tmpData.up_id,
        eid: this.tmpData.down_id,
        typeid: this.tmpData.typeid,
      };
      this.$http
        .postData("/Index/getShipMent", { ...form })
        .then((res) => {
          let tmp = this.classifyArrayGroupBySameFieldAlpha(res.list, "date");
          this.dataList = tmp;

          // this.maxHeight = tmp.length*50 + res.list.length*85

          this.loading = false;
          this.hasLoad = true;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    goUrl(url) {
      if (!url) return;
      if (url == "not") {
        this.$notFound();
        return;
      }
      this.$router.push(url);
    },
    classifyArrayGroupBySameFieldAlpha(arr, filed) {
      let temObj = {};
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        if (!temObj[item[filed]]) {
          temObj[item[filed]] = [item];
        } else {
          temObj[item[filed]].push(item);
        }
      }
      let resArr = [];
      let len = 0;
      Object.keys(temObj).forEach((key, idx) => {
        // len += temObj[key].length
        resArr.push({
          date: key,
          // show:idx<2?true:false,
          // len:temObj[key].length,
          // ac_num:len,
          // height: temObj[key].length*85 + 50,
          list: temObj[key],
        });
      });
      return resArr;
    },
  },
};
</script>
  <style scoped>
.filter {
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
}
.top {
  line-height: 28px;
  padding: 20px;
  padding-bottom: 0px;
}
.top .item {
  font-size: 14px;
}
.mybox {
}
.mybox .tips {
  font-size: 12px;
  color: #666;
  padding: 0 10px;
}
.mybox .item {
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}
.mybox .title {
  color: #000000;
  line-height: 30px;
  font-size: 14px;
}
.mybox .price {
  font-size: 10px;
}
.mybox .price b {
  color: #ff5a00;
  font-size: 18px;
  font-weight: 300;
}

.mybox .left {
  text-align: left !important;
  padding-left: 10px !important;
}

.mybox .item span {
  font-size: 0.6rem;
  color: #999999;
}
.hot_line {
  background: #ffffff;
  border-radius: 10px;
  margin: 10px;
  font-size: 12px;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.3);
}
.hot_line .wbox {
  position: relative;
  height: 65px;
  line-height: 24px;
  overflow: hidden;
}
.hot_line .start {
  position: absolute;
  text-align: center;
  left: 10px;
  width: 70px;
  top: 10px;
  font-size: 20px;
}
.hot_line .end {
  position: absolute;
  text-align: center;
  right: 10px;
  top: 10px;
  font-size: 20px;
  width: 70px;
}
.hot_line .center {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 10px;
  line-height: 20px;
  text-align: center;
}
.hot_line .trans {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 36px;
  line-height: 20px;
  text-align: center;
}
.hot_line .bgs {
  background: #f3f3f3;
  color: #666666;
  display: inline-block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  margin-right: 4px;
}
.hot_line .info {
  font-size: 14px;
  border-top: 1px dashed #dedede;
  padding-top: 10px;
  margin: 0 20px;
  height: 50px;
}
.hot_line .info .jd {
  font-size: 12px;
  color: #666666;
  padding-top: 5px;
}
.hot_line .price b {
  color: #ff5a00;
  font-weight: 300;
  font-size: 18px;
}
.hot_line .jt {
  border-bottom: 1px solid #2c92ff;
  position: absolute;
  left: 34%;
  right: 34%;
  top: 26px;
  display: flex;
  justify-content: right;
}
.hot_line .jt .arrow {
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 0px 10px;
  border-color: transparent transparent transparent #2c92ff;
  /* transform: rotate(45deg); */
}
</style>
  