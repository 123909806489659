<template>
  <div class="hello">
    <van-sticky v-if="detail">
      <van-nav-bar
        style="background: rgba(49, 151, 253, 1); color: #ffffff"
        @click-left="onClickLeft"
      >
        <template #title><span style="color: #ffffff">查看订单</span></template>
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
      </van-nav-bar>
    </van-sticky>
    <template v-if="hasLoad">
      <div class="tops">
        <van-cell-group inset title="基本信息">
          <van-cell title="单号" :value="detail.order_sn" />
          <van-cell title="状态" :value="detail.status_txt" />
          <van-cell title="游船" :value="detail.title.split('#')[0]" />
          <van-cell title="航线" :value="detail.title.split('#')[1]" />
          <van-cell title="房型" :value="detail.title.split('#')[2]" />
          <van-cell title="出行日期" :value="detail.godate" />
          <van-cell title="创建时间" :value="detail.create_time" />
          <van-cell
            title="备注"
            v-if="detail.contact.else"
            :value="detail.contact.else"
          />
        </van-cell-group>
        <van-cell-group inset title="预订详情">
          <template v-for="(p, idx) in detail.room.price_list">
            <van-cell
              v-if="p.num > 0"
              :key="idx"
              :title="p.title"
              :value="'￥' + p.value + '/人*' + p.num"
            />
          </template>
          <van-cell title="小计"
            ><span class="price"
              >￥<span class="sp">{{ detail.boat_price }}</span></span
            ></van-cell
          >
        </van-cell-group>
        <van-cell-group inset title="保险">
          <div v-if="hasBaoxian">
            <template v-for="(p, idx) in detail.baoxian">
              <van-cell
                v-if="p.num > 0"
                :key="idx"
                :title="p.title"
                :value="'￥' + p.price + '/人*' + p.num"
              />
            </template>
            <van-cell title="小计"
              ><span class="price"
                >￥<span class="sp">{{ detail.baoxian_price }}</span></span
              ></van-cell
            >
          </div>
          <van-cell v-else title="小计"
            ><span class="price"
              >￥<span class="sp">{{ detail.baoxian_price }}</span></span
            ></van-cell
          >
        </van-cell-group>
        <van-cell-group inset title="其他项目">
          <div v-if="hasGoods">
            <template v-for="(p, idx) in detail.goods">
              <van-cell
                v-if="p.num > 0"
                :key="idx"
                :title="p.title"
                :value="'￥' + p.price + '/人*' + p.num"
              />
            </template>
            <van-cell title="小计"
              ><span class="price"
                >￥<span class="sp">{{ detail.goods_price }}</span></span
              ></van-cell
            >
          </div>
          <van-cell v-else title="小计"
            ><span class="price"
              >￥<span class="sp">{{ detail.goods_price }}</span></span
            ></van-cell
          >
        </van-cell-group>
        <van-cell-group inset title="旅客信息">
          <template v-for="(p, idx) in detail.user_list" :key="idx">
            <van-cell :title="p.name" :value="p.phone" :label="p.idcard" />
          </template>
        </van-cell-group>
        <van-cell-group inset title="费用">
          <van-cell title="总金额" :value="detail.total"
            ><span class="price"
              >￥<span class="sp">{{ detail.total }}</span></span
            ></van-cell
          >
          <van-cell
            :title="detail.status > 1 ? '实付' : '待付'"
            :value="detail.pay"
            ><span class="price"
              >￥<span class="sp">{{ detail.pay }}</span></span
            ></van-cell
          >
          <van-cell title="补差" v-if="parseFloat(detail.discount) != 0"
            ><span class="price"
              >{{ parseFloat(detail.discount) < 0 ? "退" : "补" }}￥{{
                parseFloat(detail.discount) < 0
                  ? -parseFloat(detail.discount)
                  : parseFloat(detail.discount)
              }}</span
            ></van-cell
          >
        </van-cell-group>
        <van-cell-group inset title="付款信息">
          <van-cell title="付款方式">{{
            detail.pay_type_txt ?? "未支付"
          }}</van-cell>
          <van-cell title="付款时间">{{
            detail.pay_time ?? "未支付"
          }}</van-cell>
          <van-cell title="交易号" :value="detail.trans_no ?? '未支付'" />
        </van-cell-group>
        <template v-if="detail.status == 1">
          <van-popup
            v-model:show="viewPayBox"
            round
            position="bottom"
            :style="{ minHeight: '120px' }"
          >
            <van-radio-group v-model="pay_way">
              <van-cell-group inset title="">
                <template #title>
                  <div
                    style="
                      text-align: center;
                      height: 40px;
                      line-height: 40px;
                      padding-bottom: 10px;
                      font-size: 14px;
                      color: #333;
                      border-bottom: 1px solid #f3f3f3;
                    "
                  >
                    选择支付方式
                  </div>
                </template>
                <van-cell
                  v-for="(item, idx) in payArr"
                  :key="idx"
                  :title="item.title"
                  clickable
                  center
                  size="large"
                  @click="selectPay(item.tag)"
                >
                  <template #right-icon>
                    <van-radio :name="item.tag" />
                  </template>
                  <template #icon>
                    <van-icon
                      :name="item.icon"
                      style="font-size: 24px; margin-right: 6px"
                    />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <div style="padding: 30px 20px; text-align: center">
              <van-button type="success" block :loading="loading" @click="goPay"
                >立即支付</van-button
              >
              <div style="padding-top: 10px">
                <van-button
                  type="default"
                  plain
                  block
                  @click="goUrl('/page/payment')"
                  >更多付款方式</van-button
                >
              </div>
            </div>
          </van-popup>
          <div class="btns" v-if="detail.status == 1 && !viewPayBox">
            <van-button
              type="primary"
              block
              :loading="loading"
              @click="viewPayBox = true"
              >去支付</van-button
            >
          </div>
        </template>
      </div>

      <div style="height: 80px"></div>
    </template>
    <van-empty v-else description="请稍后" />
    <my-footer :view="hasLoad" :showElse="false"></my-footer>
  </div>
</template>
  <script>
import wx from "weixin-js-sdk"; // 引入微信JS-SDK库
import { ref } from "vue";
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";

export default {
  name: "makeOrder",
  components: {
    MyFooter,
  },
  data() {
    return {
      hasLoad: false,
      loading: false,
      from: false,
      detail: false,
      oid: false,
      hasBaoxian: false,
      hasGoods: false,
      step: 1,
      inMini: false,
      inWechat: false,
      miniData: false,
      viewPayBox: false,
      payArr: [
        {
          title: "微信支付",
          tag: "wxpay",
          icon: "/img/wxpay.jpg",
        },
      ],
      pay_way: "wxpay",
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    this.oid =
      this.$route.params.oid != null && this.$route.params.oid
        ? this.$route.params.oid
        : false;
    this.from =
      this.$route.query.from != null && this.$route.query.from
        ? this.$route.query.from
        : false;
    if (!this.oid) {
      showToast("页面不存在");
      setTimeout(() => {
        history.back();
      }, 1500);
      return;
    }
    this.isInMini();

    this.inWechat = this.isWx();

    if (!this.inMini && !this.inWechat) {
      this.payArr.push({
        title: "支付宝",
        tag: "alipay",
        icon: "/img/alipay.png",
      });
    }
    this.getDetail();
  },
  methods: {
    isInMini() {
      let that = this;
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信环境下
        wx.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
            that.inMini = true;
          } else {
            that.inMini = false;
          }
        });
      } else {
        that.inMini = false;
      }
    },
    goUrl(url) {
      this.$router.push({ path: url });
    },
    goPay() {
      let openid = localStorage.getItem("openid");
      // 支付方式为微信时要特殊判断
      this.miniData = false;
      if (this.pay_way == "wxpay") {
        if (this.inMini) {
          // 如果是小程序
          this.miniData = {
            oid: this.detail.id,
            pay: this.detail.pay,
          };
          let miniUrl = `/pages/doPay/doPay?payData=${JSON.stringify(
            this.miniData
          )}`;
          wx.miniProgram.navigateTo({
            url: miniUrl,
          });
          return;
        } else {
          // 普通情况
          if (this.inWechat && !openid) {
            // 在微信中 并且没有openid
            this.getOpenId();
            return;
          }
        }
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      let that = this;
      this.$http
        .postData("/Pay/unify", {
          oid: this.oid,
          openid: openid,
          pay_way: this.pay_way,
          in_wx: this.inWechat ? 1 : 0,
          mini: this.inMini,
        })
        .then((res) => {
          this.loading = false;
          const payInfo = res; // 后端返回的支付参数
          console.log(res);
          if (this.pay_way == "alipay") {
            // 支付宝返回的是表单，插入到页面中直接提交就会调起支付宝支付页面(放弃支付时会回到之前的页面，支付宝不提供放弃支付时的页面跳转)
            const divForm = document.getElementsByTagName("divform");
            if (divForm.length) {
              document.body.removeChild(divForm[0]);
            }
            const div = document.createElement("divform");
            div.innerHTML = res; // res就是支付宝返回给你的form
            document.body.appendChild(div);
            document.forms[0].submit();
            return;
          } else {
            if (payInfo.trade_type == "MWEB") {
              // h5
              location.href = payInfo.mweb_url;
            } else if (payInfo.trade_type == "JSAPI") {
              // 调用微信JS-SDK库的支付接口
              wx.config({
                appId: payInfo.appId,
                timestamp: payInfo.timestamp,
                nonceStr: payInfo.nonceStr,
                signature: payInfo.paySign,
                jsApiList: ["chooseWXPay"],
              });
              wx.ready(function () {
                wx.chooseWXPay({
                  appId: payInfo.appId,
                  timestamp: payInfo.timestamp,
                  nonceStr: payInfo.nonceStr,
                  package: payInfo.package,
                  signType: payInfo.signType,
                  paySign: payInfo.paySign,
                  success: function (res) {
                    // 支付成功后的回调函数
                    showToast("支付成功");
                    that.getDetail();
                  },
                  fail: function (res) {
                    // 支付失败后的回调函数
                    showToast(res.errMsg);
                    console.log("支付失败", res);
                  },
                });
              });
            } else if (payInfo.trade_type == "MINI") {
              // 小程序
            }
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    selectPay(val) {
      this.pay_way = val;
    },
    isWx() {
      var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
      var isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
      return isWeixin;
    },
    getOpenId() {
      let auth_url =
        "https://m.xingchuanly.com/wechat/Auth/authorize?hurl=" +
        location.href.split("#")[0];
      let redirect_url = window.location.href;
      localStorage.setItem("redirect_url", redirect_url + "?from=authorize");
      window.location.href = auth_url;
    },
    getDetail() {
      this.hasLoad = false;
      this.$http
        .getDetail({
          table: "Order",
          oid: this.oid,
        })
        .then((res) => {
          this.detail = res;
          this.hasLoad = true;
          let tmp = res.baoxian.filter((item) => {
            return item.num > 0;
          });
          if (tmp.length > 0) {
            this.hasBaoxian = true;
          }
          let tmp2 = res.goods.filter((item) => {
            return item.num > 0;
          });
          if (tmp2.length > 0) {
            this.hasGoods = true;
          }
          if (this.from != false) {
            this.goPay();
          }
        })
        .catch((error) => {});
    },
  },
};
</script>
    <style scoped>
.hello {
  min-height: 100vh;
  background: #f3f3f3;
}
.tops {
  margin: 20px 0;
}
.price {
  color: #cc0000;
  font-size: 12px;
}
.price .sp {
  font-size: 14px;
}
.btns {
  text-align: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100000;
  height: 50px;
  padding: 6px 20px;
  background: #ffffff;
}

.submit_ok {
  margin: 20px;
  border-radius: 10px;
}
.submit_ok .icon {
  text-align: center;
  font-size: 80px;
}
</style>
    