<template>
  <div class="hello">
    <template v-if="hasLoad">
      <van-sticky>
        <van-nav-bar
          style="background: rgba(49, 151, 253, 1); color: #ffffff"
          @click-left="onClickLeft"
        >
          <template #title
            ><span style="color: #ffffff">{{ pro.nav_title }}</span></template
          >
          <template #left
            ><span style="color: #ffffff">
              <van-icon
                name="arrow-left"
                style="color: #ffffff; margin-right: 6px"
              />返回</span
            ></template
          >
        </van-nav-bar>
      </van-sticky>
      <div class="headbox" ref="headbox">
        <div class="price" v-if="detail.mins > 0">
          <small>￥</small>{{ detail.mins }}<small>起</small>
        </div>
        <div class="price" v-else>电询</div>
        <van-swipe :autoplay="3000" lazy-render style="height: 300px">
          <van-swipe-item v-for="(item, i) in pro.imgs" :key="i">
            <van-image
              fit="cover"
              :src="item.url + '?x-oss-process=image/resize,m_mfit,w_400,h_300'"
              style="width: 100%; height: 300px"
            ></van-image>
          </van-swipe-item>
        </van-swipe>
        <div class="htm">
          <div class="title">
            <b>{{ pro.title }}</b
            ><span class="stitle" v-if="detail.stitle">{{
              detail.stitle
            }}</span>
          </div>
          <div class="notice" v-if="detail.tips">
            <van-icon name="info-o" /> {{ detail.tips }}
          </div>
        </div>
      </div>
      <div class="topMain">
        <div class="select_box">
          <div class="select_title">
            选择航线<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items">
              <van-tag
                class="item"
                type="primary"
                :plain="line.id != sid"
                v-for="(line, idx) in detail.lineArr"
                @click="selectLine(line.id)"
                :key="idx"
              >
                {{ line.matou.up_info.title }}({{
                  line.matou.up_info.times
                }})<van-icon name="arrow" />{{ line.matou.down_info.title }}({{
                  line.matou.down_info.times
                }})
              </van-tag>
            </div>
          </div>
        </div>
        <div class="select_box">
          <div class="select_title">
            出行日期<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items" v-if="detail.dateArr">
              <!-- <van-tag class="item" style="padding:8px;" :plain="day.date != date" @click="selectDay(idx)" type="primary" v-for="(day,idx) in detail.dateArr" :key="idx">
                                {{day.date}} <br/>￥{{ day.price }}
                            </van-tag> -->
              <van-tag
                class="item"
                style="padding: 8px; text-align: center"
                plain
                @click="selectDay(idx)"
                :type="day.date != date ? 'default' : 'primary'"
                v-for="(day, idx) in detail.dateArr"
                :key="idx"
              >
                {{ day.date.split("-").splice(1).join("-") }} <br />￥{{
                  day.price
                }}
              </van-tag>
            </div>
            <div class="items" v-else>
              <div
                style="
                  width: 100%;
                  text-align: center;
                  font-size: 12px;
                  color: #666;
                "
              >
                没有记录
              </div>
            </div>
          </div>
        </div>
        <div class="roomBox" v-if="roomArr">
          <template v-for="(item, idx) in roomArr.room_list" :key="idx">
            <div class="item" @click="viewRoomInfo(item)">
              <div class="thumb">
                <van-image
                  fit="cover"
                  :src="
                    item.thumb +
                    '?x-oss-process=image/resize,m_mfit,w_100,h_100'
                  "
                  style="height: 100%"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <div class="con">
                <div class="title">{{ item.title }}</div>
                <div class="cs">
                  <template v-for="cs in item.content">
                    <div v-if="checkRcs(cs.title)">
                      {{ checkRcs(cs.title) }}:{{ cs.value }}
                    </div>
                  </template>
                </div>
                <span class="rights">
                  <span
                    class="price"
                    v-if="
                      item.price_list[0].value &&
                      parseInt(item.price_list[0].value) > 0
                    "
                    >￥<b>{{ item.price_list[0].value }}</b></span
                  >
                  <span class="price" v-else><b>电询</b></span>
                  <div class="views">
                    <template v-if="item.video"
                      ><font-awesome-icon
                        icon="play-circle"
                      />视频展示</template
                    >
                    <template v-else
                      ><font-awesome-icon
                        icon="search-plus"
                      />房型详情</template
                    >
                  </div>
                </span>
              </div>
            </div>
          </template>
          <div class="room_tips" v-if="room_tips">
            <div class="title"><van-icon name="info-o" /> 温馨提醒:</div>
            <div class="info" v-html="room_tips"></div>
          </div>
        </div>
        <!-- 没有出行价格 -->
        <div class="roomBox" v-else>
          <div class="item" v-for="(item, idx) in detail.room_list" :key="idx">
            <div class="thumb">
              <van-image
                fit="cover"
                :src="
                  item.thumb + '?x-oss-process=image/resize,m_mfit,w_100,h_100'
                "
                style="height: 100%"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <div class="con">
              <div class="title">{{ item.title }}</div>
              <div class="cs">
                <template v-for="cs in item.content">
                  <div v-if="checkRcs(cs.title)">
                    {{ checkRcs(cs.title) }}:{{ cs.value }}
                  </div>
                </template>
              </div>
              <span class="rights">
                <span class="price">￥<b>电询</b></span>
                <div class="views">
                  <template v-if="item.video"
                    ><font-awesome-icon icon="play-circle" />视频展示</template
                  >
                  <template v-else
                    ><font-awesome-icon icon="search-plus" />房型详情</template
                  >
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <template>
          <p v-for="(sconfig, ix) in detail.show_config" :key="ix">
            {{ sconfig.title }}
          </p>
        </template>
      </div>
      <div class="dibb" v-if="hasLoad && detail">
        <van-tabs v-model:active="tabActive" scrollspy sticky>
          <div v-for="(sconfig, ism) in detail.show_config" :key="ism">
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'boat' && sconfig.status"
            >
              <div class="box">
                <van-divider
                  :style="{
                    color: '#1989fa',
                    borderColor: '#1989fa',
                    padding: '0 10px',
                  }"
                  >游轮介绍</van-divider
                >
                <template v-for="(child, sbc) in detail.show_config">
                  <div
                    class="cansu"
                    v-if="child.name == 'cansu' && child.status == 1"
                    :key="sbc"
                  >
                    <van-row gutter="10">
                      <van-col
                        span="12"
                        v-for="(item, i) in detail.boat_info.cs"
                        :key="i"
                      >
                        <div class="item">
                          {{ item.title }}：{{ item.value }}
                        </div>
                      </van-col>
                    </van-row>
                  </div>
                </template>
                <div
                  class="boat_video"
                  v-if="detail.boat_info && detail.boat_info.video"
                >
                  <video
                    ref="myPlayer"
                    :src="detail.boat_info.video"
                    t7-video-player-ext="true"
                    webkit-playsinline="true"
                    x5-playsinline="true"
                    controls
                    style="width: 100%; height: 240px"
                  ></video>
                </div>
                <div
                  class="content"
                  v-if="detail.boat_info"
                  v-html="
                    detail.boat_info.content
                      ? detail.boat_info.content
                      : '未填写'
                  "
                ></div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'xingcheng' && sconfig.status"
            >
              <div class="box">
                <van-divider
                  :style="{
                    color: '#1989fa',
                    borderColor: '#1989fa',
                    padding: '0 10px',
                  }"
                  >行程安排</van-divider
                >
                <div class="xingcheng">
                  <div
                    class="item"
                    v-for="(day, idx) in detail.content"
                    :key="idx"
                  >
                    <span class="day">第{{ idx + 1 }}天</span>
                    <div class="title">{{ day.food }}</div>
                    <div class="text" v-html="day.content"></div>
                  </div>
                </div>
                <van-divider
                  :style="{
                    color: '#cc0000',
                    borderColor: '#1989fa',
                    padding: '0 10px',
                  }"
                  >费用说明</van-divider
                >
                <div class="content" v-html="detail.price_info"></div>
                <van-divider
                  :style="{
                    color: '#cc0000',
                    borderColor: '#1989fa',
                    padding: '0 10px',
                  }"
                  >注意事项</van-divider
                >
                <div class="content" v-html="detail.notice"></div>
                <van-divider
                  :style="{
                    color: '#cc0000',
                    borderColor: '#1989fa',
                    padding: '0 10px',
                  }"
                  >退改规则</van-divider
                >
                <div class="content" v-html="detail.back"></div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'sesi' && sconfig.status"
            >
              <div class="box">
                <van-divider
                  :style="{
                    color: '#1989fa',
                    borderColor: '#1989fa',
                    padding: '0 10px',
                  }"
                  >游轮设施</van-divider
                >
                <div class="content">
                  <van-row
                    gutter="20"
                    v-if="detail.boat_info && detail.boat_info.sesi"
                  >
                    <van-col
                      span="12"
                      v-for="(item, i) in detail.boat_info.sesi"
                      :key="i"
                    >
                      <div class="sesi_item">
                        <div class="thumb">
                          <van-image
                            fit="cover"
                            :src="item.url"
                            style="height: 100%"
                          >
                            <template v-slot:loading>
                              <van-loading type="spinner" size="20" />
                            </template>
                          </van-image>
                        </div>
                        <div class="title">{{ item.name.split(".")[0] }}</div>
                      </div>
                    </van-col>
                  </van-row>
                  <van-empty v-else description="数据整理中" />
                </div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'service' && sconfig.status"
            >
              <div class="box">
                <template v-if="detail.boat_info && detail.boat_info">
                  <van-divider
                    :style="{
                      color: '#1989fa',
                      borderColor: '#1989fa',
                      padding: '0 10px',
                    }"
                    >游轮服务</van-divider
                  >
                  <div
                    class="contentcontent_sp"
                    v-html="detail.boat_info.service"
                  ></div>
                </template>
                <van-empty v-else description="数据整理中" />
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'activity' && sconfig.status"
            >
              <div class="box">
                <template v-if="detail.boat_info && detail.boat_info">
                  <van-divider
                    :style="{
                      color: '#1989fa',
                      borderColor: '#1989fa',
                      padding: '0 10px',
                    }"
                    >游轮活动</van-divider
                  >
                  <div
                    class="contentcontent_sp"
                    v-html="detail.boat_info.activity"
                  ></div>
                </template>
                <van-empty v-else description="数据整理中" />
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'food' && sconfig.status"
            >
              <div class="box">
                <template v-if="detail.boat_info && detail.boat_info">
                  <van-divider
                    :style="{
                      color: '#1989fa',
                      borderColor: '#1989fa',
                      padding: '0 10px',
                    }"
                    >游轮美食</van-divider
                  >
                  <div
                    class="content content_sp"
                    v-html="detail.boat_info.food"
                  ></div>
                </template>
                <van-empty v-else description="数据整理中" />
              </div>
            </van-tab>
          </div>
          <!-- 以下为自定义展示内容 -->
          <template v-if="detail.diy_content">
            <van-tab
              v-for="(diy, dx) in detail.diy_content"
              :title="diy.title"
              :key="dx"
            >
              <div class="box">
                <van-divider
                  :style="{
                    color: '#1989fa',
                    borderColor: '#1989fa',
                    padding: '0 10px',
                  }"
                  >{{ diy.title }}</van-divider
                >
                <div class="contentcontent_sp" v-html="diy.content"></div>
              </div>
            </van-tab>
          </template>
        </van-tabs>
      </div>
    </template>
    <van-empty v-else description="请稍后" />
    <!-- 查看房型 -->
    <van-popup
      v-model:show="viewRoom"
      round
      position="bottom"
      :style="{ height: '560px' }"
    >
      <div class="view_room" ref="view_room" v-if="viewRoom">
        <div class="title">{{ tmpRoomInfo.title }}</div>
        <div class="imgs">
          <van-swipe :autoplay="1000000" lazy-render>
            <van-swipe-item v-if="tmpRoomInfo.video">
              <video
                ref="myPlayer"
                :src="tmpRoomInfo.video"
                controls
                t7-video-player-ext="true"
                webkit-playsinline="true"
                x5-playsinline="true"
                style="width: 100%; height: 200px"
              ></video>
            </van-swipe-item>
            <van-swipe-item v-for="(item, i) in tmpRoomInfo.imgs" :key="i">
              <van-image
                fit="cover"
                :src="
                  item.url + '?x-oss-process=image/resize,m_mfit,w_400,h_400'
                "
                style="width: 100%; height: 200px"
              ></van-image>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="cansu">
          <div
            class="item"
            v-for="(item, idx) in tmpRoomInfo.content"
            :key="idx"
          >
            {{ item.title }} : {{ item.value }}
          </div>
        </div>
        <div style="margin: 20px; text-align: center">
          <van-button @click="makeOrderOnly" type="primary" block
            >立即预订</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 直接预订 -->
    <van-popup
      v-model:show="viewMakeOrder"
      round
      position="bottom"
      :style="{ height: '500px' }"
    >
      <div class="makeOrderOne" v-if="viewMakeOrder">
        <div class="title">选择产品</div>
        <div class="select_box">
          <div class="select_title">
            选择航线<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items">
              <van-tag
                class="item"
                type="primary"
                :plain="line.id != sid"
                v-for="(line, idx) in detail.lineArr"
                @click="selectLine(line.id)"
                :key="idx"
              >
                {{ line.matou.up_info.title }}({{
                  line.matou.up_info.times
                }})<van-icon name="arrow" />{{ line.matou.down_info.title }}({{
                  line.matou.down_info.times
                }})
              </van-tag>
            </div>
          </div>
        </div>
        <div class="select_box">
          <div class="select_title">
            出行日期<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items" v-if="detail.dateArr">
              <van-tag
                class="item"
                :plain="day.date != date"
                @click="selectDay(idx)"
                type="primary"
                v-for="(day, idx) in detail.dateArr"
                :key="idx"
              >
                {{ day.date.split("-").splice(1).join("-") }}
              </van-tag>
            </div>
            <div class="items" v-else>
              <div
                style="
                  width: 100%;
                  text-align: center;
                  font-size: 12px;
                  color: #666;
                "
              >
                没有记录
              </div>
            </div>
          </div>
        </div>
        <div class="select_box">
          <div class="select_title">
            选择房型<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items" v-if="roomArr.room_list">
              <template v-for="(item, idx) in roomArr.room_list">
                <van-tag
                  class="item"
                  style="padding: 8px; text-align: center"
                  :plain="roomData && item.id == roomData.id ? false : true"
                  @click="selectRoom(item)"
                  type="primary"
                  v-if="parseInt(item.price_list[0].value) > 0"
                  :key="idx"
                >
                  {{ item.title }} <br />￥{{ item.price_list[0].value }}
                </van-tag>
              </template>
            </div>
            <div class="items" v-else>
              <div
                style="
                  width: 100%;
                  text-align: center;
                  font-size: 12px;
                  color: #666;
                "
              >
                没有记录
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 20px; text-align: center">
          <van-button @click="makeOrderTwo" type="primary" block
            >下一步</van-button
          >
        </div>
      </div>
    </van-popup>

    <van-action-bar class="ftbtn" v-if="detail">
      <van-action-bar-icon icon="wap-home-o" @click="goUrl('/')" text="首页" />
      <van-action-bar-icon
        icon="phone-o"
        @click="goUrl(detail.phone)"
        color="#ff0000"
        text="电话咨询"
      />
      <van-action-bar-icon
        icon="chat-o"
        @click="goUrl(detail.chat_url)"
        text="客服"
        dot
      />
      <van-action-bar-button
        @click="beforeMakeOrder"
        type="primary"
        text="立即预订"
      />
    </van-action-bar>
    <my-footer :view="hasLoad" :showElse="false" :showBar="true"></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import MyFooter from "@/components/myFooter.vue";
import ShareBox from "@/components/ShareBox.vue";
import RoomInfo from "@/components/RoomInfo.vue";

import {
  showNotify,
  showToast,
  BarrageProps,
  BarrageItem,
  BarrageInstance,
} from "vant";
export default {
  name: "detail",
  components: {
    RoomInfo,
    MyFooter,
    ShareBox,
  },
  data() {
    return {
      tab_act: -1,
      tmpArr: [],
      hasLoad: false,
      loading: false,
      atype: false,
      viewRoom: false,
      tmpRoomInfo: false,
      viewMakeOrder: false,
      tabActive: 0,
      sid: 0,
      detail: false,
      roomArr: false,
      dateIdx: 0,
      roomData: false,
      orderData: false,
      active: 0,
      date: false,
      pro: false, //区分显示航线或游船信息
      room_tips: false,
      loadShare: false,
      shareData: false,
    };
  },
  setup() {
    // const router = useRouter();
    // router.beforeEach((to, from) => {
    //   if (to.hash) {
    //     const element = document.querySelector(to.hash);
    //     if (element) {
    //         element.scrollIntoView({ behavior: 'smooth' })
    //     }
    //     return
    //   }
    // });
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    // 模型
    this.atype =
      this.$route.params.type != null && this.$route.params.type
        ? this.$route.params.type
        : false;
    // ID
    this.sid =
      this.$route.params.sid != null && this.$route.params.sid
        ? this.$route.params.sid
        : false;
    this.date =
      this.$route.params.date != null && this.$route.params.date
        ? this.$route.params.date
        : false;

    if (!this.atype || !this.sid) {
      let _this = this;
      showToast("非法请求");
      setTimeout(function () {
        _this.goUrl("/");
      }, 100);
      return;
    }
    localStorage.removeItem("tmpData");
    this.room_tips = localStorage.getItem("room_tips");
    this.getDetail();
  },
  methods: {
    makeOrderTwo() {
      if (!this.roomData) {
        showToast("请选择房型");
        return;
      }
      // 组合订单数据
      this.orderData = {
        lid: this.detail.id,
        bid: this.detail.bid,
        rid: this.roomData.id,
        rtitle: this.roomData.title,
        ltitle: this.detail.title,
        btitle: this.detail.btitle,
        thumb: this.detail.boat_info.thumb,
        date: this.date,
        room: this.roomData,
      };

      localStorage.setItem("tmpData", JSON.stringify(this.orderData));
      this.$router.push({ path: "/makeOrder" });
    },
    makeOrderOnly() {
      this.viewRoom = false;
      this.selectRoom(this.tmpRoomInfo);
      this.makeOrderTwo();
    },
    beforeMakeOrder() {
      this.viewMakeOrder = true;
      this.roomData = false;
    },
    viewRoomInfo(room) {
      if (!parseInt(room.price_list[0].value) > 0) {
        return;
      }
      this.viewRoom = true;
      this.tmpRoomInfo = room;
    },
    selectLine(id) {
      this.sid = id;
      this.date = false;
      this.roomData = false;
      this.getDetail();
    },
    selectDay(idx) {
      this.dateIdx = idx;
      this.roomArr = this.detail.dateArr[idx];
      this.roomData = false;
      this.date = this.roomArr.date;
    },
    selectRoom(item) {
      let tmp = JSON.parse(JSON.stringify(item));
      delete tmp.content;
      delete tmp.video;
      delete tmp.imgs;
      this.roomData = tmp;
    },
    checkRcs(val) {
      let arr = ["结构", "面积", "入住人数"];
      let tit = false;
      arr.forEach((item) => {
        if (val.indexOf(item) != -1) {
          tit = item;
          return;
        }
      });
      return tit;
    },
    litenScrollTop() {
      let _this = this;
      setTimeout(() => {
        document.querySelectorAll("img").forEach((item) => {
          item.style.width = "100%";
        });
      }, 500);
    },
    scrollToAnchor(val) {
      const targetElement = document.querySelector("#tab" + val);
      // targetElement.scrollIntoView({ behavior: 'smooth' })
      window.scrollTo({
        top: targetElement.offsetTop - 40,
        behavior: "smooth",
      });
    },
    scrollToTop() {
      var curScrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      let flag = true;
      const len = this.tmpArr.length;
      for (let i = len - 1; i >= 0; i--) {
        const curReference = this.tmpArr[i].top; // 当前参考值
        if (flag && curScrollTop >= curReference - 50) {
          flag = false;
          this.tab_act = i;
          return;
        }
      }
      // this.tmpArr.forEach((item,idx)=>{
      //     if(item.top >= scrollTop){
      //         this.tab_act = idx+1
      //         console.log(item.top,scrollTop)
      //     }
      // })
    },
    openApps() {
      this.viewApps = true;
    },
    goUrl(url) {
      if (url.indexOf("tell") >= 0) {
        this.callPhone(url.split(":")[1]);
        return;
      }
      if (url.indexOf("http") >= 0) {
        window.location.href = url;
        return;
      }
      this.$router.push({ path: url });
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    getDetail() {
      this.loading = true;
      this.$http
        .getDetail({ table: "Bline", id: this.sid, date: this.date })
        .then((res) => {
          this.loading = false;
          this.hasLoad = true;
          this.detail = res;
          if (this.atype == "boat") {
            // 入口为游船
            this.pro = {
              title: res.boat_info.title,
              nav_title: res.boat_info.title,
              imgs: res.boat_info.imgs,
              thumb: res.boat_info.thumb,
            };
          }
          if (this.atype == "line") {
            // 入口为航线
            this.pro = {
              title: res.title,
              nav_title: res.title,
              imgs: res.imgs,
              thumb: res.thumb,
            };
          }
          this.detail.show_config.map((item) => {
            if (item.title == "行程") {
              item.title = "行程安排";
            } else if (item.title == "设施") {
              item.title = "游轮设施";
            } else if (item.title == "游船活动") {
              item.title = "游轮活动";
            } else if (item.title == "游船美食") {
              item.title = "游轮美食";
            } else if (item.title == "游船服务") {
              item.title = "游轮服务";
            } else if (item.title == "游船设施") {
              item.title = "游轮设施";
            } else if (item.title == "游船介绍") {
              item.title = "游轮介绍";
            }
          });
          // 处理当前选项
          this.roomArr = res.dateArr.length > 0 ? res.dateArr[0] : false;
          this.room_id = 0;
          this.date = res.date;
          this.litenScrollTop();
          // 同步更新页面标题
          document.title =
            (this.atype == "boat" ? res.btitle : res.title) + "|详情";
          this.shareData = {
            title: res.title,
            desc: res.share_desc ? res.share_desc : res.stitle,
            link: false, // 默认获取当前页面 要分享的页面地址不允许存在#参数
            imgUrl:
              process.env.VUE_APP_HOST +
              res.thumb +
              "?x-oss-process=image/resize,m_mfit,w_120,h_120", // 分享出去链接图片
          };
          this.loadShare = true;
        })
        .catch((error) => {
          setTimeout(function () {
            history.back();
            this.loading = true;
          }, 1000);
        });
    },
  },
};
</script>
  <style scoped>
.hello {
  padding-bottom: 120px;
}
.page_title {
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  background: rgba(49, 151, 253, 1);
  color: #ffffff;
  text-align: center;
}
.room_tips {
  margin: 10px;
  padding-bottom: 10px;
}
.room_tips .title {
  font-size: 14px;
}
.room_tips .info {
  line-height: 24px;
  font-size: 12px;
  color: #666666;
}
.view_room {
}
.view_room .title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 1.2rem;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.3);
}
.view_room .imgs {
  margin: 10px;
}
.view_room .imgs .thumb {
  width: 100%;
}
.view_room .cansu {
  margin: 0 20px;
  line-height: 28px;
  font-size: 14px;
}
.roomBox {
  background: #f3f3f3;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3);
}
.roomBox .item {
  margin: 1px 0;
  height: 100px;
  position: relative;
  background: #ffffff;
  display: flex;
}
.roomBox .item .thumb {
  border-radius: 5px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-left: 10px;
}
.roomBox .item .con {
  padding: 10px;
  padding-left: 30px;
}
.roomBox .item .con .title {
  font-size: 0.8rem;
  font-weight: 400;
  color: #000000;
}
.roomBox .item .con .rights {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 12px;
  display: block;
  text-align: right;
}
.roomBox .item .con .views {
  color: rgba(49, 151, 253, 1);
  padding: 10px 0;
}
.roomBox .item .con .price b {
  color: #ff5400;
  font-size: 1.2rem;
  font-weight: 300;
}
.roomBox .item .cs {
  line-height: 20px;
  font-size: 12px;
  color: #666666;
}

.headbox {
  position: relative;
  min-height: 300px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3);
}
.headbox .htm {
  padding: 10px 20px;
}
.headbox .title {
  color: #000000;
}
.headbox .title b {
  font-size: 16px;
  color: #000000;
  line-height: 26px;
  font-weight: bold;
}
.headbox .stitle {
  font-size: 12px;
  color: #666;
  line-height: 20px !important;
  margin-left: 6px;
  font-weight: 400;
}
.headbox .notice {
  font-size: 12px;
  color: #bc5a29;
  line-height: 24px;
  padding-bottom: 10px;
}
.headbox .price {
  font-size: 24px;
  color: #ffffff;
  line-height: 40px;
  padding: 0 10px;
  background: #ff5400d1;
  position: absolute;
  font-weight: 500;
  left: 0;
  top: 236px;
  z-index: 10;
  border-radius: 0 20px 0 20px;
}
.headbox small {
  font-size: 14px;
}
.topMain {
  margin: 20px auto;
}
.topMain .select_box {
  margin: 0;
  margin-bottom: 20px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3);
}
.topMain .select_title {
  font-size: 1rem;
  height: 40px;
  line-height: 40px;
  background: #f3f3f3;
  padding-left: 10px;
}
.topMain .select_title small {
  font-size: 0.7rem;
  line-height: 40px;
  color: #666666;
  padding-left: 6px;
}

.topMain .select_con {
  padding: 10px;
  padding-bottom: 0px;
}
.topMain .select_con .items {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  justify-items: center;
  padding-bottom: 10px;
}
.topMain .select_con .item {
  flex: 0 0 auto;
  margin-right: 5px;
  height: 28px;
}

.makeOrderOne {
  margin: 20px;
}
.makeOrderOne .title {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.makeOrderOne .select_box {
  margin: 0;
  margin-bottom: 20px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3);
}
.makeOrderOne .select_title {
  font-size: 1rem;
  height: 40px;
  line-height: 40px;
  background: #f3f3f3;
  padding-left: 10px;
}
.makeOrderOne .select_title small {
  font-size: 0.7rem;
  line-height: 40px;
  color: #666666;
  padding-left: 6px;
}

.makeOrderOne .select_con {
  padding: 10px;
  padding-bottom: 0px;
}
.makeOrderOne .select_con .items {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  justify-items: center;
  padding-bottom: 10px;
}
.makeOrderOne .select_con .item {
  flex: 0 0 auto;
  margin-right: 5px;
  height: 28px;
}

.xingcheng {
  padding: 0 20px;
}
.xingcheng .item {
  margin-left: 10px;
  position: relative;
}
.xingcheng .item .day {
  position: absolute;
  z-index: 1;
  left: -40px;
  top: 5px;
  background: rgba(49, 151, 253, 0.8);
  color: #ffffff;
  display: block;
  border-radius: 0 10px 10px 0;
  padding: 0px 10px;
  font-size: 12px;
  line-height: 20px;
}
.xingcheng .item .title {
  color: rgba(49, 151, 253, 1);
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  padding-left: 20px;
}
.xingcheng .item .text {
  font-size: 12px;
  line-height: 24px;
  overflow-x: hidden;
}
.xingcheng .item .text p {
  display: inline;
}

.myTabs {
  height: 47px;
  line-height: 47px;
  background: #f3f3f3;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}
.myTabs .active {
  color: rgba(49, 151, 253, 1);
  font-weight: 600;
}
.cansu {
  margin: 0 10px;
}
.cansu .item {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 6px;
}
.box {
  margin: 10px;
  padding: 0;
}
.box .content {
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  color: #333;
  overflow-x: hidden;
  min-height: 80px;
}
.box .content_sp img {
  width: 100% !important;
}
.box .content p {
  word-wrap: break-word;
}
/* 设施 */
.sesi_item {
  margin-bottom: 10px;
}
.sesi_item .thumb {
  height: 120px;
  border-radius: 6px;
  overflow: hidden;
}
.sesi_item .title {
  line-height: 24px;
  text-align: center;
  color: #666;
}
.ftbtn {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px;
  border-top: 1px solid #f3f3f3;
}
</style>
  